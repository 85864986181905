import { isWindowAvailable } from 'utils/browser';

const getValuesFromLocalStorageWithKey = (key: string) => {
  if (!isWindowAvailable) return [];

  try {
    const rawValue = window.localStorage.getItem(key);
    const values = rawValue ? JSON.parse(rawValue) : [];
    return values.every((value: any) => typeof value === 'string') ? values : [];
  } catch {
    return [];
  }
};

const saveValuesToLocalStorageWithKey = (key: string, values: string[]) => {
  if (!isWindowAvailable) return;

  try {
    window.localStorage.setItem(key, JSON.stringify(values));
  } catch {
    /* Does nothing for now, potentially could log to Elastic APM later */
  }
};

const customerIdToKey = (customerId: string | undefined) =>
  `multisearch:${(!!customerId && customerId) || '-1'}`;

export const getValuesFromLocalStorage = (customerId: string | undefined) =>
  getValuesFromLocalStorageWithKey(customerIdToKey(customerId));

export const saveValuesToLocalStorage = (customerId: string | undefined, values: string[]) =>
  saveValuesToLocalStorageWithKey(customerIdToKey(customerId), values);

import React, { ChangeEventHandler, FC, memo, useCallback, useEffect, useState } from 'react';

import { TextArea } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Forms';
import { Modal } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal';
import { ModalCloseEvent } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal/Modal';
import { ModalButton } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal/Buttons';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { ModalCloseLabels, ModalCloseOptions } from 'constants/modals/modalCloseOptions';
import { mediaBreakpointUp } from 'utils/mediaQueries';

import { SCROLL_RESTORE_KEY } from 'constants/keys';
import { urls } from 'constants/urls';
import usePrevious from 'hooks/use-previous';

import { singleResizeWatcher } from 'utils/single-event-watcher';
import { textToCleanValues, textToValues, valuesToText } from './utils';

import { getValuesFromLocalStorage, saveValuesToLocalStorage } from './localStorage';

import styles from './MultiSearchModal.module.scss';

const MAX_MULTISEARCH_VALUES = 100;

const modalButtons = ({ enableSaveButton }: { enableSaveButton: boolean }) => {
  const ctaButtons = [
    {
      buttonText: ModalCloseLabels.CLEAR_LIST,
      buttonValue: ModalCloseOptions.CLEAR_LIST,
      disabled: !enableSaveButton,
      theme: 'secondary',
    } as ModalButton,
    {
      buttonText: ModalCloseLabels.SEARCH,
      buttonValue: ModalCloseOptions.SAVE,
      disabled: !enableSaveButton,
      theme: 'primary',
    } as ModalButton,
  ];
  return mediaBreakpointUp('md') ? ctaButtons : ctaButtons.reverse();
};

export type MultiSearchModalProps = {
  isOpen: boolean;
  mobile: boolean;
  toggleModal: Function;
  onSearch: Function;
};

const MultiSearchModal: FC<MultiSearchModalProps> = memo(
  ({ isOpen, mobile, toggleModal, onSearch }) => {
    const { state: authState } = useAuthentication();

    const { customerId } = authState;

    const [text, setText] = useState('');
    const [showMaxLengthMessage, setShowMaxLengthMessage] = useState(false);

    useEffect(() => {
      // We can only initialise the values client-side once we have the customerId
      const initialMultiSearchValues = getValuesFromLocalStorage(customerId);
      const initialMultiSearchText = valuesToText(initialMultiSearchValues);

      setText(initialMultiSearchText);
      setShowMaxLengthMessage(initialMultiSearchText.length >= MAX_MULTISEARCH_VALUES);
    }, [customerId]);

    const previousOpen = usePrevious(isOpen);

    if (!previousOpen && isOpen && !mobile) {
      singleResizeWatcher(() => {
        toggleModal();
      });
    }

    const goToMultiSearchPage = useCallback(() => {
      const cleanValues = textToCleanValues(text);
      window.location.href = `${urls.multiSearch}?value=${cleanValues[0]}`;
    }, [text]);

    const onClose = useCallback<(event: ModalCloseEvent) => void>(
      event => {
        switch (event.value) {
          case ModalCloseOptions.CLEAR_LIST:
            setText('');
            saveValuesToLocalStorage(customerId, []);
            return;
          case ModalCloseOptions.SAVE:
            if (onSearch) {
              onSearch();
            }
            try {
              // Clear scrollRestoration state, to prevent Multi-Search page from
              // just showing the previous products, since we are doing a new search.
              // (Could consider extending this to history.push in general and clear
              // it on any non-back-button navigations, if we need to).
              window.sessionStorage.removeItem(SCROLL_RESTORE_KEY);
            } catch (e) {
              // Does nothing for now, but consider logging to Elastic APM
            }
            saveValuesToLocalStorage(customerId, textToCleanValues(text));
            goToMultiSearchPage();
            return;
          default:
            saveValuesToLocalStorage(customerId, textToCleanValues(text));
            toggleModal();
        }
      },
      [customerId, goToMultiSearchPage, onSearch, text, toggleModal],
    );

    const modalProps = {
      buttons: modalButtons({ enableSaveButton: !!text.length }),
      isOpen,
      label: `Multiple item search. Press Enter after each item.`,
      fullScreen: mobile,
      handleModalClose: onClose,
      titleText: 'Multi-search',
    };

    const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
      let newText = event.target.value;

      const list = textToValues(newText);

      const reachedMaxLength = list.length >= MAX_MULTISEARCH_VALUES;
      setShowMaxLengthMessage(reachedMaxLength);

      if (reachedMaxLength) {
        newText = list.slice(0, MAX_MULTISEARCH_VALUES).join('\n');
      }

      setText(newText);
    };

    const textAreaProps = {
      autoFocus: true,
      className: styles.textArea,
      id: 'multi-search-textarea',
      label: 'Multiple item search. Press Enter after each item.',
      labelHidden: true,
      onChange: onTextChange,
      placeholder: 'Type or paste your list here',
      value: text,
      rows: 5,
    };

    return (
      <Modal {...modalProps}>
        <div className={styles.content}>
          <TextArea {...textAreaProps} />
          {showMaxLengthMessage && (
            <span
              aria-live="assertive"
              className={styles.maxLengthMessage}
              data-testid="max-length-list-message"
            >
              Item limit reached
            </span>
          )}
        </div>
      </Modal>
    );
  },
);

MultiSearchModal.displayName = 'MultiSearchModal';

export { MultiSearchModal };

import React, { FC, memo, useCallback, useState } from 'react';

import classNames from 'classnames';
import { ChevronRight } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';

import MultiSearchModal from './modal';

import styles from './MultiSearch.module.scss';

export type MultiSearchProps = {
  hidden: boolean;
  mobile: boolean;
  onToggleModal: Function;
  onSearch: Function;
};

const MultiSearch: FC<MultiSearchProps> = memo(({ hidden, mobile, onToggleModal, onSearch }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    const newOpen = !modalOpen;
    setModalOpen(newOpen);
    if (onToggleModal) {
      onToggleModal(newOpen);
    }
  }, [modalOpen, onToggleModal]);

  const buttonProps = {
    'aria-label': 'Search multiple items',
    className: classNames({
      [styles.multiSearchButton]: !mobile,
      [styles.mobileMultiSearchButton]: mobile,
      [styles.hidden]: hidden,
    }),
    'data-testid': 'multi-search-button',
    disabled: hidden,
    onClick: toggleModal,
    role: 'button',
    tabIndex: 0,
  };

  return (
    <>
      <button {...buttonProps} type="button">
        Multi-search
      </button>
      {mobile && <ChevronRight size="xsmall" className={styles.chevronRightIcon} />}
      {modalOpen && (
        <MultiSearchModal isOpen mobile={mobile} toggleModal={toggleModal} onSearch={onSearch} />
      )}
    </>
  );
});

MultiSearch.displayName = 'MultiSearch';

export { MultiSearch };

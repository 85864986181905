import React from 'react';
import { Container } from '@johnlewispartnership/wtr-ingredients/dist/foundations/grid';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';
import { urls, specialistShopLinks } from 'constants/urls';
import formatShopLink from './utils/formatShopLink';
import styles from './TopNav.module.scss';

const { customerServiceHomePage } = urls;

export const TopNav = () => (
  <nav className={styles.wrapper} role="navigation" aria-label="Top Navigation Bar">
    <Container className={styles.topNavContainer}>
      <div className={`row ${styles.row}`}>
        <div className={styles.specialistShopLinksWrapper} data-testid="specialist-shop-links">
          <span className={styles.legend}>More from Waitrose: </span>
          {specialistShopLinks.map(link => (
            <TextLink
              key={`specialistShops-desktop-${link.name}-link`}
              className={styles.specialistShopLink}
              href={formatShopLink(link)}
              underline="hover"
              rel="noopener noreferrer"
              target="_blank"
            >
              {link.name}
            </TextLink>
          ))}
        </div>

        <div className={styles.customerServiceLinksWrapper} data-testid="customer-service-links">
          <TextLink
            href="https://www.waitrose.com/find-a-store/"
            key="customerService-find-a-store-link"
            underline="hover"
            className={styles.customerServiceLink}
            data-testid="find-a-store"
          >
            Find a store
          </TextLink>

          <TextLink
            href={customerServiceHomePage}
            key="customerService-link"
            underline="hover"
            className={styles.customerServiceLink}
            data-testid="customer-service"
          >
            Customer service
          </TextLink>
        </div>
      </div>
    </Container>
  </nav>
);

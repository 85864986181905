import { isWindowAvailable } from 'utils/browser';

import { BREAKPOINTS } from 'constants/grid';

const SSR_BREAKPOINT = 'lg';
// We need to eliminate this whole module and stop doing JS responsiveness for SSR's sake
const currentBreakpointEqualOrGreaterThan = breakpoint =>
  isWindowAvailable
    ? window.matchMedia(`(min-width: ${breakpoint[1]}px)`).matches
    : BREAKPOINTS[breakpoint] >= BREAKPOINTS[SSR_BREAKPOINT];

const getBodyPropertyValue = () =>
  getComputedStyle(document.querySelector('body'), ':before')
    .getPropertyValue('content')
    .replace(/"/g, '');

export const getCurrentBreakpoint = () =>
  Object.entries(BREAKPOINTS)
    .filter(currentBreakpointEqualOrGreaterThan)
    .map(([breakpoint]) => breakpoint)
    .pop();

export function mediaBreakpointCurrent() {
  if (!isWindowAvailable) return SSR_BREAKPOINT;
  return getBodyPropertyValue() || 'lg';
}

function mediaBreakpointPosition(breakpoint) {
  return Object.keys(BREAKPOINTS).indexOf(breakpoint || mediaBreakpointCurrent());
}

export function mediaBreakpointUp(breakpoint) {
  return mediaBreakpointPosition() >= mediaBreakpointPosition(breakpoint);
}

export function mediaBreakpointDown(breakpoint) {
  return mediaBreakpointPosition() <= mediaBreakpointPosition(breakpoint);
}

export function mediaBreakpointOnly(breakpoint) {
  return mediaBreakpointUp(breakpoint) && mediaBreakpointDown(breakpoint);
}

export function mediaBreakpointBetween(minBreakpoint, maxBreakpoint) {
  return mediaBreakpointUp(minBreakpoint) && mediaBreakpointDown(maxBreakpoint);
}

export const getBreakpointName = (breakpoint = {}) =>
  breakpoint.name === 'default' ? 'xl' : breakpoint.name;

export const isValidAtBreakpoint = (
  componentBreakpoints,
  currentBreakpoint = getCurrentBreakpoint(),
) =>
  componentBreakpoints.filter(
    breakpoint => getBreakpointName(breakpoint) === currentBreakpoint && breakpoint.visible,
  ).length > 0;

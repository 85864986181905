/*
 *  Ensure link paths are useable
 *
 *  Internal AEM paths are converted to front-end links,
 *  otherwise the url or path is returned unmodified.
 */
import { rootPath } from 'utils/root-path';

const AEM_PATH_PREFIX = '/content/waitrosegroceriescms/en/';
const AEM_CONTENT = 'wtr-content';

export default (path: string) => {
  let url = path;

  /*
    NOTE: This will transform any internal AEM paths into front-end paths, however the mapping isn't always 1-to-1.
    WPIP-56480 will explore this in more detail.
  */
  if (url.includes(AEM_PATH_PREFIX)) {
    // deepcode ignore RegExpStringInReplace - This is a false positive. String.replace() accepts string or regex.
    const frontEndPath = url.replace(AEM_PATH_PREFIX, '').replace(AEM_CONTENT, 'content');

    url = rootPath(frontEndPath);
  }

  return url;
};

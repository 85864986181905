export enum ModalCloseOptions {
  CANCEL = 'CANCEL',
  CLEAR = 'CLEAR',
  CLEAR_LIST = 'CLEAR_LIST',
  CONFIRM = 'CONFIRM',
  DELETE = 'DELETE',
  OK = 'OK',
  REMOVE = 'REMOVE',
  SAVE = 'SAVE',
}

export enum ModalCloseLabels {
  CANCEL = 'Cancel',
  CLEAR = 'Clear',
  CLEAR_LIST = 'Clear list',
  SAVE = 'Save',
  SEARCH = 'Search',
  CLOSE = 'Close',
}

import { animate, EASING } from 'utils/animate';

const focusTop = () => {
  // Need to set tabIndex to -1 to make body focusable, as it is when the
  // browser first loads the page. Setting 0 introduces an extra tabstop.
  window.document.body.tabIndex = -1;
  window.document.body.focus({ preventScroll: true });
};

export function setBodyNoScroll(preventScroll) {
  if (preventScroll) {
    document.body.classList.add('bodyNoScroll');
  } else {
    document.body.classList.remove('bodyNoScroll');
  }
}

const DEFAULT_SLOW_DURATION = 600;

const duration = DEFAULT_SLOW_DURATION;

const startAnimation = ({ actuator, end, start, target }) =>
  animate({ actuator, duration, easing: EASING.outCubic, end, start, target });

const scrollToPageY = y =>
  startAnimation({
    actuator: value => window.scrollTo(0, value),
    end: y,
    start: window.pageYOffset,
  });

export const slowlyScrollPageVerticallyToTop = () => {
  scrollToPageY(0).finally(() => {
    setTimeout(focusTop, 0);
  });
};

export const removeDiacriticsFromLetters = (string: string) => {
  if (!string) return '';

  const diacritics = 'ÀàÁáÄäÂâÈèÉéËëÊêÌìÍíÏïÎîÒòÓóÖöÔôÙùÚúÜüÛûÑñÇç·/_,:;';
  const nonDiacritics = 'AaAaAaAaEeEeEeEeIiIiIiIiOoOoOoOoUuUuUuUuNnCc------';
  let newString = string;

  for (let idx = 0, len = diacritics.length; idx < len; idx += 1) {
    newString = newString.replace(
      new RegExp(diacritics.charAt(idx), 'g'),
      nonDiacritics.charAt(idx),
    );
  }

  return newString;
};

export const textToValues = (text: string = '') =>
  text
    .replace(/\n|_/g, ',')
    .split(',')
    .filter(item => item && item.length > 0);

export const textToCleanValues = (text: string = '') =>
  text
    .replace(/\n|_/g, ',')
    .split(',')
    .map(item => removeDiacriticsFromLetters(item.trim()))
    .filter(item => item && item.length > 0);

export const valuesToText = (values: string[] = []) => values.join('\n');
